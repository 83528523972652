.Output{
	width: 100%;
	min-height: 100%;
	padding-bottom: 10px;
}

.Log{
	&[data-type="default"]{
		color: var(--primary-text-color);
	}

	&[data-type="success"]{
		color: var(--success-text-color);
	}

	&[data-type="error"]{
		color: var(--danger-text-color);
	}
}

.Link{
	color: var(--primary-text-color);
	text-decoration: underline;
}