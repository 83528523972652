.Item{
	display: grid;
	grid-template-columns: 55px 1fr;
	grid-column-gap: 5px;
}

.Code{
	color: var(--sidebar-primary-text-color);
}

.Description{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.Empty{
	width: 100%;
	margin: 10px 0 20px;
	color: var(--secondary-bg-icons-color);
}