.List{
	column-gap: 12px;
	padding: 0 20px;
}

.IconRisk{
	position: relative;

	&[data-risk = "no-risk"]{
		:global(.fill){
			fill: var(--no-risk);
		}
	}

	&[data-risk = "medium-risk"]{
		:global(.fill){
			fill: var(--medium-risk);
		}
	}

	&[data-risk = "medium-high-risk"]{
		:global(.fill){
			fill: var(--medium-high-risk);
		}
	}

	&[data-risk = "high-risk"]{
		:global(.fill){
			fill: var(--high-risk);
		}
	}

	// Disabled
	// &[data-disabled = "true"] {
	// 	:global(.fill) {
	// 		fill: red;
	// 	}
	// }


}