.Label{
	margin-right: 40px;
}

.Progress{
	width: 100%;
	height: 8px;
	background: var(--alternative-secondary-bg-color);
	position: relative;

	.ProgressBar{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background: var(--alternative-primary-color);

		&:after{
			content: '';
			display: block;
			width: 1px;
			height: 12px;
			background: inherit;
			position: absolute;
			top: -2px;
			right: 0;
		}
	}

	&.Warning{
		.ProgressBar{
			background: var(--warning-text-color);
		}
	}

	&.Danger{
		.ProgressBar{
			background: var(--danger-text-color);
		}
	}
}