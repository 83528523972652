.Wrapper{
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 20px;
}

.List{
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 10px;
}

.Item{
    min-height: 38px
}

.Label{
    cursor: pointer;

    &.disabled{
        cursor: not-allowed;
    }
}

.Text{
    display: -webkit-box!important;
    color: var(--primary-text-color);

    &.disabled{
        color: var(--disable-text-color);
    }
}

.ArrowUp{
    cursor: pointer;

    :global(.fill){
        fill: var(--primary-bg-icons-color);
    }
}

@media (max-width: 768px) {
    .Wrapper{
        grid-template-columns: minmax(0, 1fr);

        & > div:last-child{
            grid-row: 1;
        }
    }
}