.Pagination {
    min-height: 60px;
    border: 1px solid var(--alternative-secondary-bg-color);
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    // Select
    .Select {
        width: 75px;
        height: 2rem; 
        outline: none; 
        cursor: pointer; 
        border: none;
        border-radius: 3px;
        padding: 0 0.25rem;
    }
}