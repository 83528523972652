.MenuSearchComponent{
	padding-right: 1.5rem;
}

.Wrapper{
	position: relative;
}

.Button{
	cursor: pointer;

	.ProfileName,
	.ProfilePosition{
		color: var(--alternative-primary-text-color);
	}

	// Icon
	.Icon{
		svg{
			width: 24px;
			height: 24px;
		}

		:global(.fill){
			fill: var(--alternative-primary-icons-color);
		}
	}

}

.DropdownLayer{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
}

.Dropdown{
	position: fixed;
	top: 70px;
	right: 0;
	left: 0;
	border: 1px solid var(--sidebar-primary-text-color);
	border-top: none;
	background: var(--sidebar-primary-color);
	z-index: 7;
	height: 56px;
	width: 100%;
}

.DropdownLink{
	text-decoration: none;
}

@media (max-width: 768px) {
	.MenuSearchComponent{
		order: 3;
	}
}