.InfoItem{
    overflow: hidden;
    padding: 10px 0;

    .ItemLabel{
        flex-grow: 1;
    }

    .ItemValue{
    }
}

.Empty{
    width: 100%;
    margin: 10px 0 20px;
    color: var(--secondary-bg-icons-color);
}