.Wrapper {
	background: var(--primary-bg-color);
	border-radius: 10px;
	padding: 20px;
	height: calc(100% - 48px);
	margin: 24px 36px;
}

.HeaderContainer{
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 800px;

	.Title{
		margin-right: 1.75rem;
	}
}

.ScrollWrapper{
	overflow: hidden;
}

.Container{
	max-width: 800px;
}

.Filter{
	margin-left: 90px;
}

.ViewIcon{
	margin-right: 15px;

	:global(.fill){
		fill: var(--primary-bg-icons-color);
	}
}

.ModuleBox{
	margin-bottom: 30px;

	&:last-child{
		margin-bottom: 40px;
	}
}

.Module{
	padding: 10px 0;
	border-bottom: 1px solid var(--alternative-primary-color);
	margin-bottom: 40px;
}

.ModuleTitle{
	color: var(--sidebar-primary-text-color);
}

@media (max-width: 768px) {
	.Wrapper {
		background: var(--primary-bg-color);
		border-radius: 10px;
		padding: 10px 15px;
		height: calc(100% - 20px);
		margin: 10px 10px;
	}


	.HeaderContainer{
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.Title{
			margin-right: 5px;
		}
	}

	.HeaderSubmit{
		border-bottom: 1px solid var(--alternative-secondary-bg-color);
		padding-bottom: 1.5rem;
	}
}