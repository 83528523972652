.Grid{
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	grid-column-gap: 65px;
	width: 100%;
}

.Item{
	display: grid;
	grid-template-columns: minmax(0, 1fr) max-content;
	grid-column-gap: 10px;
	padding: 10px 0;
	border-bottom: 1px solid var(--alternative-secondary-bg-color);
	overflow: hidden;

	&.Last{
		border-bottom: none;
	}
}

.ItemLabel{
	color: var(--primary-text-color);
	flex-grow: 1;
}

.ItemValue{
	flex-shrink: 0;
	width: 60px;
	color: var(--primary-text-color)
}