.List{
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 20px;
}

.Label{
    cursor: pointer;

    &.disabled{
        cursor: not-allowed;
    }
}

.Text{
    color: var(--primary-text-color);

    &.disabled{
        color: var(--disable-text-color);
    }
}

.ArrowUp{
    cursor: pointer;

    :global(.fill){
        fill: var(--primary-bg-icons-color);
    }
}