.Logo {
    padding: 0 4rem 0 2rem;
    display: flex;
    align-items: center;

    svg{
        height: 40px;
        width: auto;
    }
}

@media (max-width: 768px) {
    .Logo{
        order: 2;
        flex-grow: 1;
        padding: 0 0.5rem 0 1rem;

        svg{
            height: 30px;
        }
    }
}