.TextRow{
    padding-bottom: 0.75rem;
}

.Label{
    min-width: 120px;
    font-weight: bold;
}

.Value{
    overflow: hidden;
}

.Copy{
    cursor: pointer;
    padding-left: 0.5rem;
}

.Contacts{
    padding-top: 1rem;
}

.FormRow{
    padding-bottom: 1rem;
    width: 100%;

    .Value{
        flex-grow: 1;

        // For the dropdown
        & > div{
            width: 100%;

            & > div {
                width: 100%;
            }
        }
    }

    .Label{
        min-width: 65px;
    }

    .InputNumber{
        border-radius: 5px!important;
        height: 32px!important;
    }
}

.TimerButton{
    width: 92px;
    height: 92px;
    border-radius: 92px;
    position: relative;
    cursor: pointer;

    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

    &._disabled{
        cursor: not-allowed;
        background: #F5F5F5;
        opacity: 0.5;
    }

    .Button{
        :global(.fill){
            fill: var(--success-button-bg-color);
        }
    }

    .progressCircle{
        transform: rotate(-90deg);
        width: 92px;
        height: 92px;

        position: absolute;
        top: 0;
        left: 0;

        .progressBar{
            stroke-width: 2px;
        }
    }

    &._active{
        .Button{
            :global(.fill) {
                fill: var(--danger-button-bg-color);
            }
        }

        .progressCircle{
            .progressBar {
                //transition: stroke-dashoffset 1s linear;
                stroke: var(--secondary-button-text-color);
            }
        }
    }
}