.Menu{
	height: 100%;
	background-color: var(--primary-bg-color);

	// MenuItems
	.MenuItems {

		// Item
		.Item {
			cursor: pointer;
			padding: 1rem 1.5rem;

			// Hover
			&:hover {
				background-color: var(--alternative-secondary-bg-color);
			}

			// Active
			&[data-active="true"] {
				background-color: var(--alternative-secondary-bg-color);
			}
		}
	}
}