.List{
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	grid-row-gap: 10px;
}

.Item{
	padding: 10px;
	border-radius: 10px;
	border: 1px solid var(--alternative-secondary-bg-color);
}

.Icon{
	margin-right: 13px;
}