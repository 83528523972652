.SnoozeRenderer {

    // Block Title
    .BlockTitle {
        color: var(--sidebar-primary-text-color);
        font-weight: 600;
        font-size: 12px;
    }

    // Period Button
    .PeriodButton {
        color: var(--sidebar-primary-text-color);
        font-weight: normal;
        height: 24px;
        border-radius: 10px;
    }

    // Reason Button
    .ReasonButton {
        flex-grow: 1;
        color: var(--sidebar-primary-text-color);
        font-weight: normal;
        height: 38px;
        justify-content: flex-start;

        // Active
        &.Active {
            background-color: #3B54D0; // var(--sidebar-primary-text-color);
            color: #FFFFFF; //var(--primary-button-text-color);
        }
    }

    // DatePicker
    .DatePicker {
        color: var(--sidebar-primary-text-color);
        border: 1px solid var(--alternative-secondary-bg-color);
        border-radius: 6px;
        width: 147px;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        background-color: var(--primary-bg-color);
        color: var(--primary-text-color);
        outline: none;

        &:placeholder-shown {
            border-color: var(--alternative-secondary-bg-color);
        }

        // Placeholder
        &::-webkit-input-placeholder {
            color: var(--sidebar-text-color);
        }
        &::-moz-placeholder {
            color: var(--sidebar-text-color);
        }
        &:-ms-input-placeholder {
            color: var(--sidebar-text-color);
        }
        &:-moz-placeholder {
            color: var(--sidebar-text-color);
        }
    }
}

// Reason Tooltip
.ReasonTooltip {
    position: relative;

    &:before {
        content: attr(data-tooltip);
        display: none;
        background-color: var(--secondary-button-bg-color);
        color: var(--primary-text-color);
        border: 1px solid var(--alternative-secondary-bg-color);
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 2;
        top: 50%;
        right: calc(100% + 10px);
        transform: translateY(-50%);
    }

    &:after {
        content: '';
        display: none;
        position: absolute;
        top: 50%;
        right: calc(100% + 6px);
        transform: translateY(-50%) rotate(45deg);
        width: 8px;
        height: 8px;
        background-color: var(--secondary-button-bg-color);
        border-right: 1px solid var(--alternative-secondary-bg-color);
        border-top: 1px solid var(--alternative-secondary-bg-color);
        z-index: 10;
    }

    &:hover {
        &:before {
            display: block;
        }

        &:after {
            display: block;
        }
    }
}