.Wrapper{
	cursor: pointer;

	&:hover{
		.Text:after{
			opacity: 1;
		}
	}
}

.Text, .ActiveText{
	position: relative;
	cursor: pointer;
	padding-bottom: 5px;
	transition: color 0.2s;

	&:after{
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		border-radius: 100px;
		background: var(--alternative-primary-color);
		position: absolute;
		bottom: 0;
		left: 0;
		transition: opacity 0.2s;
		opacity: 0;
	}
}

.Text{
	color: var(--primary-text-color);
}

.ActiveText{
	color: var(--alternative-primary-color);

	&:after{
		opacity: 1;
	}
}