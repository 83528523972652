.FileLoader{
	background: var(--primary-bg-color);
	border: 2px dashed;
	border-color: var(--disable-icons-color);
	border-radius: 21px;
	width: 520px;
	height: 320px;
	cursor: pointer;

	.Icon{
		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	.Label{
		color: var(--sidebar-text-color);
		font-size: 30px!important;
		line-height: 40px!important;
		margin-bottom: -40px;
	}

	&.Hover{
		border-color: var(--primary-bg-icons-color);

		.Icon{
			:global(.fill){
				fill: var(--primary-bg-icons-color);
			}
		}
	}
}

