.ListWrapper{
    overflow-y: auto;
}

.Title{
    padding-left: 14px;
    position: relative;

    &:before{
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--alternative-primary-color);
        border-radius: 4px;
    }
}

.FilterMob{
    & > div{
        width: 100%;
    }
}

.List{
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 15px;
}

.Patient{
    background: var(--primary-bg-color);
    border: 1px solid var(--alternative-secondary-bg-color);
    border-radius: 12px;

    .Info{
        padding: 15px;
    }

    .Name{
        margin-bottom: 4px;
    }

    .More{
        padding: 0 15px 15px;
        cursor: pointer;

        .MoreText{
            color: var(--sidebar-primary-text-color);
        }

        .ArrowIcon {
            width: 24px;
            height: 24px;
            transition: transform .2s;

            :global(.fill) {
                fill: var(--primary-bg-icons-color);
            }

            &.ArrowIconActive{
                transform: rotate(-180deg) translateY(-10%);
            }
        }
    }

    .ModulesList{
        transition: max-height 0.5s ease-out;
        max-height: 0;
        overflow: hidden;

        &.active{
            transition: max-height 0.5s ease-in;
            max-height: 2000px;
        }
    }

    .ModuleRow{
        padding: 20px 15px;
        border-top: 1px solid var(--alternative-secondary-bg-color);
    }
}

.ExportLink{
    .ExportLinkIcon{
        margin-right: 8px;

        :global(.fill){
            fill: var(--primary-bg-icons-color);
        }
    }

    a{
        text-decoration: none;
        transition: color 0.3s;

        &:hover{
            color: var(--sidebar-primary-text-color)
        }
    }
}