.MenuProjectComponent{
	padding-right: 2rem;
	//overflow: hidden;
}

.Wrapper{
	position: relative;
	//overflow: hidden;
}

.MenuProject{
	cursor: pointer;
	overflow: hidden;

	.HospitalName,
	.ProjectName{
		max-width: 200px;
		overflow: hidden;
		white-space: nowrap;
		color: var(--alternative-primary-text-color);
	}

	.HospitalIcon{
		padding-right: 0.75rem;
		padding-left: 0.75rem;

		:global(.fill){
			fill: var(--alternative-primary-text-color);
		}
	}

	// Arrow Icon
	.ArrowIcon{

		svg{
			width: 24px;
			height: 24px;
		}

		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	.Info{
		overflow: hidden;
	}
}

.DropdownLayer{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
}

.Dropdown{
	position: absolute;
	top: 60px;
	right: 0;
	border: 1px solid var(--alternative-primary-color);
	border-top: none;
	background: var(--sidebar-primary-color);
	z-index: 7;
	width: 280px;
	border-radius: 0 0 6px 6px;
	box-shadow: var(--shadow);
}

.Clinic{
	&._active{
		.ClinicHeader{
			background: var(--sidebar-hover-color);

			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
				background: var(--alternative-primary-color);
			}

			.ClinicIcon, .ClinicArrowIcon{
				:global(.fill){
					fill: var(--primary-bg-icons-color);
				}
			}

			.ClinicTitle{
				color: var(--sidebar-primary-text-color);
			}
		}
	}

	&._toggled{
		.Projects{
			max-height: 100vh;
			transition: max-height 1s;
		}

		.ClinicHeader{
			.ClinicArrowIcon svg{
				transform: rotate(180deg);
			}
		}
	}

	&._open .Projects{
		max-height: unset;
	}
}

.ClinicHeader{
	padding: 1rem;
	position: relative;
	cursor: pointer;
	width: 100%;
	overflow: hidden;

	&:hover{
		background: var(--sidebar-hover-color);
	}

	.ClinicIcon{
		padding-right: 1rem;

		svg{
			width: 24px;
			height: 24px;
		}
		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	.ClinicTitleWrapper{
		overflow: hidden;
	}

	.ClinicTitle{
		color: var(--primary-text-color);
		white-space: nowrap;
	}

	.ClinicArrowIcon{

		svg{
			width: 24px;
			height: 24px;
		}

		:global(.fill){
			fill: var(--primary-text-color);
		}
	}
}

.Projects{
	transition: max-height 0.5s;
	max-height: 0;
	overflow-y: hidden;

}


.List{
	padding: 0.75rem 0;
	flex-grow: 1;
	width: 100%;

	border-bottom: 1px solid var(--secondary-button-border-color);
}

.Project{
	position: relative;
	cursor: pointer;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	width: 100%;
	overflow: hidden;
	flex-shrink: 0;

	&._active{
		background: var(--sidebar-hover-color);

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 4px;
			height: 100%;
			background: var(--alternative-primary-color);
		}
	}

	&:hover{
		background: var(--sidebar-hover-color);
	}

	.ProjectTitleWrapper{
		overflow: hidden;
	}

	.ProjectTitle{
		white-space: nowrap;
	}

	.IDEButton{
		min-width: 65px;
	}
}

@media (max-width: 1200px) {
	.MenuProjectComponent{
		padding-right: 0.5rem;
	}

	.MenuProject{
		.Info{
			display: none;
		}

		.ArrowIcon{
			display: none;
		}
	}
}

@media (max-width: 768px){
	.MenuProjectComponent{
		order: 4;
		padding-right: 1.5rem;
	}

	.Dropdown{
		top: 70px;
		min-width: unset;
		width: 100%;
		position: fixed;
	}

	.MenuProject{
		.HospitalIcon{
			padding-right: 0;
			padding-left: 0;
		}
		.Info{
			display: none;
		}

		.ArrowIcon{
			display: none;
		}
	}
}