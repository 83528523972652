.UserInfo{
	padding-bottom: 14px;
}

.UserData{
	padding: 10px 0;
	border-bottom: 1px solid var(--alternative-secondary-bg-color);
}

.Tabs{
	width: 100%;
	height: auto;
	overflow-x: auto;

	&::-webkit-scrollbar {
		width: unset;
		height: unset;
	}

	&::-webkit-scrollbar-track {
		background-color: unset;
		border-radius: unset;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: unset;
		background-color: unset;
	}
}