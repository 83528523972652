.Item {
    padding: 15px 0;
    border-bottom:  1px solid var(--alternative-secondary-bg-color);

    &:last-child{
        border-bottom: none;
    }
}

.Empty{
    width: 100%;
    margin: 10px 0 20px;
    color: var(--secondary-bg-icons-color);
}


.Info{
    padding-left: 20px;

    .Approved{

        .ApprovedIcon{
            span, svg{
                width: 16px;
                height: 16px;
            }

            :global(.fill){
                fill: var(--success-icons-color);
            }
        }

        .ApprovedText{
            color: var(--success-text-color);
        }
    }
}