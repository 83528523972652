.Patients {
    position: relative;

    .PatientsList{
        background: var(--primary-bg-color);
        width: 100%;
    }

    // Loading
    .Loading {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(128, 128, 128, 0.125);
        z-index: 1;
    }
}