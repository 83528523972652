.Cell {
	height: 100%;
}

.Wrapper {
	background: var(--primary-bg-color);
	border-radius: 10px;
	padding: 20px;
	height: 100%;
}

.Grid{
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-column-gap: 24px;
	grid-row-gap: 18px;
}

.Diagnose{
	background: var(--primary-bg-color);
	border: 1px solid var(--alternative-secondary-bg-color);
	border-radius: 10px;

	.Header{
		cursor: pointer;
		padding: 17px 12px;
		border-radius: 10px 10px 0 0;
		background-color: var(--secondary-bg-color);
		border-bottom: 1px solid var(--alternative-secondary-bg-color);
		flex-wrap: wrap;
	}

	.HeaderTexts{
		width: calc(100% - 250px);
		min-width: 200px;
	}

	.MoreIcon{
		height: 16px;

		:global(.fill){
			fill: var(--primary-text-color);
		}
	}

	.ListWrapper{
		min-height: 200px;
	}

	.List{
		//min-height: 200px
		padding: 17px 12px;
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		grid-row-gap: 14px;
		grid-template-rows: min-content;


	}

	&.NoGuidelines{
		.Header{
			border: none;
			border-radius: 10px;
		}
	}
}

.TreatmentText{
	&.done{
		text-decoration: line-through;
		color: var(--disable-button-text-color)
	}
}

.IconWrapper{
	& > span{
		display: flex;
		align-items: center;
		justify-content: center;
	}
}