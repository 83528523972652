.Dashboard{
	max-width: 100vw;
	overflow: hidden;
	height: auto!important;
}


.Grid{
	overflow: unset!important;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	grid-gap: 20px;
	grid-template-rows: 280px;
}

.Card{
	overflow:hidden;
	border-radius: 20px;
	border: 1px solid transparent;

	&._dragging{
		z-index: 10;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
		border-color: var(--alternative-primary-color);
	}
}

.Widget{
	background: var(--primary-bg-color);
	border-radius: 20px;
	height: 280px;
	position: relative;
	padding: 10px 20px;
	overflow: auto;

	&._chart{
		overflow: hidden;
	}

	&._compact{
		padding: 10px 14px;
	}

	&._large{
		padding: 10px 27px;
	}

	&._create{
		border: 1px dashed var(--disable-text-color);
		cursor: pointer;
	}
}

.ActionIconWrapper{
	position: absolute!important;
	top: 17px;
	right: 15px;
}

.ActionIcon{
	border: 1px solid var(--secondary-button-border-color)!important;
	width: 24px;
	height: 24px!important;
}

.DeleteIcon{
	position: absolute;
	top: 10px;
	right: 15px;
}

.WidgetHeader{
	height: 40px;
	padding-right: 35px;
	flex-shrink: 0;

	.HeaderMoveContainer{
		cursor: grab;

		&._dragging{
			cursor: grabbing;
		}

		.WidgetMoveIcon{
			margin-right: 7px;

			:global(.fill){
				fill: var(--secondary-bg-icons-color);
			}
		}
	}

	.WidgetTitle{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&._large{
		height: 70px;
		border-bottom: 1px solid var(--alternative-secondary-bg-color);

		.WidgetTitleIcon{
			margin-right: 10px;

			svg{
				height: 36px;
				width: 36px;
			}

			:global(.fill){
				fill: var(--disable-icons-color);
			}
		}

		&._urgent{
			.WidgetTitleIcon{
				:global(.fill){
					fill: var(--danger-icons-color);
				}
			}
		}
	}
}

.Chart{
	width: 100%;
}

.Table{
	width: 100%;
}

.Content{
	width: 100%;
}

.Label{
	align-items: center;
	height: 38px;
	padding: 4px 15px;
	background: var(--secondary-bg-color);
	border-radius: 12px;

	.LabelText{
		color: var(--primary-text-color);
	}

	.LabelIcon{
		margin-right: 2px;

		svg{
			height: 18px;
			width: 18px;

			:global(.fill){
				fill: var(--primary-text-color);
			}
		}
	}

	&._urgent{
		background: var(--danger-bg-color);

		.LabelText{
			color: var(--danger-text-color);
		}

		.LabelIcon{
			svg{
				:global(.fill){
					fill: var(--danger-icons-color);
				}
			}
		}
	}
}

.ChartEmpty{
	width: 100%;
	height: 100%;

	.ChartEmptyIcon{
		:global(.fill){
			fill: var(--sidebar-text-color);
		}
	}
}

.ChartCreate{
	width: 100%;
	height: 100%;

	.ChartCreateIcon{
		color: var(--sidebar-text-color);
		:global(.fill){
			fill: var(--sidebar-text-color);
		}
	}

	.ChartCreateText{
		color: var(--sidebar-text-color);
	}
}

.Statistics{
	width: 100%;
	align-items: center;
	justify-content: space-between;

	.StatisticWrapper{
		width: 100%;
		justify-content: center;
	}

	.StatisticContent{
		align-items: flex-end;

		.StatisticValueBlock{
			margin-right: 15px;

			.StatisticValue{
				color: var(--primary-text-color);
				//font-weight: 900;
			}
		}

		.StatisticPercentBlock{
			padding-bottom: 10px;

			svg{
				height: 18px;
				width: 18px;

				:global(.fill){
					fill: var(--sidebar-text-color);
				}
			}

			.StatisticPercent{
				padding-left: 3px;
				color: var(--sidebar-text-color)
			}
		}

		.StatisticInfo{
			width: 100%;
			align-items: flex-start;
			margin-left: 5px;

			.StatisticLabel{
				padding: 9px 22px;
				background: var(--secondary-bg-color);
				border-radius: 12px;
				margin-bottom: 8px;
			}

			.StatisticInfoTextBlock{
				.StatisticInfoText{
					color: var(--sidebar-text-color);
				}
			}
		}

		&._urgent{
			.StatisticValueBlock{
				.StatisticValue{
					color: var(--danger-text-color)
				}
			}

			.StatisticPercentBlock{
				svg{
					:global(.fill){
						fill: var(--danger-icons-color);
					}
				}

				.StatisticPercent{
					color: var(--danger-text-color)
				}
			}
		}
	}

	.StatisticLabelBlock{

		.StatisticLabel{
			color: var(--sidebar-text-color);
		}
	}
}

@media (max-width: 1600px) {
	.Grid{
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media (max-width: 1300px) {
	.Grid{
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

// Mobile version
@media (max-width: 768px) {
	.Grid{
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-gap: 12px;
	}

	.Widget{
		border-radius: 0;
		height: unset;
		min-height: 280px;
		padding: 20px 1.75rem 10px;
	}

	.WidgetHeader{
		height: unset;
		padding-right: 0;
		padding-bottom: 10px;
		flex-shrink: 0;
	}

	.ActionIconWrapper{
		top: 8px;
		right: 14px;
	}

	.Chart{
		width: 100%;

		svg{
			overflow: unset;
		}
	}
}