.UserInfo{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
	flex-grow: 1;
}

.UserInfoItem{
	overflow: hidden;

	.ItemLabel{
		color: var(--primary-text-color);
		flex-grow: 1;
		padding-right: 4px;
	}

	.ItemValue{
		flex-shrink: 0;
		width: 60px
	}
}