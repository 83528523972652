.List {

    .ListItem {
        cursor: pointer;
        margin-bottom: 1rem;

        // Text
        .Text {
            padding-left: .5rem;
        }

        // Category
        .Category {
            color: gray;
        }

        // Hover State
        &:hover {
            background-color: rgba(128, 128, 128, .125);
        }
    }
}

.InsideItem {

    ol li, ul li {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}