.Wrapper {
	background: var(--primary-bg-color);
	border-radius: 10px;
	padding: 20px;
	height: 100%;
}

.Container{
	max-width: 800px;
}

.Title{
	min-height: 38px;
}

.Filter{
	margin-left: 90px;
}

.ViewIcon{
	margin-right: 15px;

	:global(.fill){
		fill: var(--primary-bg-icons-color);
	}
}

.ModuleBox{
	margin-bottom: 30px;

	&:last-child{
		margin-bottom: 40px;
	}
}

.Module{
	padding: 10px 0;
	border-bottom: 1px solid var(--alternative-primary-color);
	margin-bottom: 40px;
}

.ModuleTitle{
	color: var(--sidebar-primary-text-color);
}

.FieldsWrapper{
	&._hasContent{
		min-height: 105%;
	}
	&._hasOneField{
		min-height: calc(100% + 1px);
	}
}