.ModuleBox{
	margin-bottom: 10px;

	&:last-child{
		margin-bottom: 40px;
	}
}

.Module{
	padding: 10px 0;
	color: var(--primary-text-color);

	.ModuleQCount{
		flex-shrink: 0;
	}

	&.active{
		color: var(--sidebar-primary-text-color);

		.ModuleTitle, .ModuleQCount{
			color: var(--sidebar-primary-text-color);
		}
	}
}

.Content{
	border-top: 1px solid var(--alternative-primary-color);
	padding: 30px 0 20px;
}



.Arrow{
	svg{
		width: 24px;
		height: 24px;
		transition: transform 0.3s;

		:global(.fill){
			fill: var(--secondary-bg-icons-color);
			transition: fill 0.3s;
		}
	}

	&.active{
		svg{
			transform: rotate(180deg);

			:global(.fill){
				fill: var(--primary-bg-icons-color);
			}
		}
	}
}

.ContentBox{
	transition: max-height 0.5s;
	max-height: 0;
	overflow-y: hidden;

	&.active{
		max-height: 100vh;
	}

	&.open{
		max-height: unset;
	}
}