.Container{
	overflow: hidden;
}

.ConfirmField{
	margin-bottom: 20px;

	.FieldTitle{
		margin-bottom: 8px;
	}
}

.ThanksWrapper{
	padding-top: 30vh;

	.Title{
		color: var(--alternative-primary-color);
	}
}