.CCMReports {
    overflow: hidden;

    .Title{
        padding-left: 14px;
        position: relative;

        &:before{
            content: '';
            display: block;
            width: 4px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: var(--alternative-primary-color);
            border-radius: 4px;
        }
    }
}