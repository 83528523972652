.Table {
    height: 100%;
    width: 100%;
    background-color: var(--primary-bg-color);
    line-height: var(--text-line-height-x1);

    & > div[role="row"] > div[role="gridcell"] {
        border-top: 1px solid var(--alternative-secondary-bg-color);
    }

    * {
        box-shadow: none;
    }

    // Theme
    --rdg-font-size: var(--text-size-x1) !important;
    --rdg-color: var(--sidebar-text-color) !important;
    --rdg-border-color: var(--primary-bg-color) !important;
    --rdg-summary-border-color: #555 !important;
    --rdg-background-color: var(--primary-bg-color) !important;
    --rdg-header-background-color: var(--primary-bg-color) !important;
    --rdg-row-hover-background-color: var(--primary-bg-color) !important;
    --rdg-row-selected-background-color: #1a73bc !important;
    --row-selected-hover-background-color: #1768ab !important;
    --rdg-checkbox-color: #94cfff !important;
    --rdg-checkbox-focus-color: #c7e6ff !important;
    --rdg-checkbox-disabled-border-color: #000 !important;
    --rdg-checkbox-disabled-background-color: #333 !important;

    :global(.rdg-cell){
        padding-inline: 6px;
        outline: none!important;
    }

    .Cell {
        height: 100%;
        overflow:hidden;
    }

    .NameCellLink{
        display: -webkit-box;
        -webkit-box-orient: vertical;

        &, & a{
            text-decoration:none;
        }
    }

    .SortCell{
        width: 16px;
    }

    .SortIcon{
        :global(.fill){
            fill: var(--primary-text-color)
        }
    }

    .HeaderCell{
        color: var(--sidebar-text-color);
        font-weight: 600;
        text-align: center;

        &:first-child{
            text-align: left;
        }

        // &:last-child{
        //     text-align: right;
        // }
    }

    .CellName{
        color: var(--primary-text-color);
        font-weight: 600;
    }

    .CallBtn{
        width: 20px;
        height: 20px;
        border-radius: 3px;

        :global(.fill){
            fill: var(--primary-button-bg-color);
        }

        &:hover{
            background: var(--primary-button-bg-color)!important;

            :global(.fill){
                fill: var(--alternative-primary-icons-color)!important;
            }
        }
    }
}