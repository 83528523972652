.Menu{
	padding: 8px 14px;
	min-width: 250px;
}

.UserInfo{
	padding-bottom: 14px;
	border-bottom: 1px solid var(--alternative-secondary-bg-color);
}

.UserData{
	padding: 10px 0;
}

.Widgets{
	margin: 0 -14px;
}

.WidgetsContent{
	padding: 0 14px 20px 14px;
}

.Widget{
	padding-bottom: 20px;

	.Title{
		padding: 15px 0;
	}
}