.Item{
	padding: 10px 0;
	overflow: hidden;
}

.ItemLabel{
	color: var(--primary-text-color);
	flex-grow: 1;
	display: block;
	padding-right: 5px;
}

.ItemValue{
	display: block;
	flex-shrink: 0;
	width: 40%;
	color: var(--primary-text-color)
}

.Empty{
	width: 100%;
	margin: 10px 0 20px;
	color: var(--secondary-bg-icons-color);
}