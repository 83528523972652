.List{
	display: grid;
	width: 100%;
	grid-template-columns: minmax(0, 1fr);
	grid-row-gap: 15px;
	grid-column-gap: 15px;
}

.Item, .Label, .Value{
	overflow: hidden;
}

.Label{
	width: calc(50% + 15px);
	min-width: 50px;
}

.Value{
	min-width: 40px;

	&.boolean{
		min-width: 20px
	}
}

.Icon{
	margin-right: 13px;
}