.Header {
    height: 60px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .15);
    background-color: var(--header-bg-color);
}

@media (max-width: 768px){
    .Header{
        height: 70px;
        box-shadow: none;
    }
}