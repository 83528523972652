.Item{
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-column-gap: 5px;
}

.Code{
	color: var(--sidebar-primary-text-color);
	font-weight: 600;
}

.Description{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}