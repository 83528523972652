.Menu{
	height: 100%;
	background-color: var(--primary-bg-color);
}

.Item {
	width: 100%;
	display: flex;
	cursor: pointer;
	align-items: center;
}

.Label{
	color: var(--sidebar-text-color);

	&.Active{
		color: var(--secondary-button-hover-color);
	}
}