.Card{
	background: var(--primary-bg-color);
	border: 1px solid var(--alternative-secondary-bg-color);
	border-radius: 10px;
}

.Header{
	padding-left: 10px;
	position: relative;

	&:before{
		content: '';
		display: block;
		width: 4px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: var(--alternative-primary-color);
		border-radius: 4px;
	}
}

.HeaderSearch{
	padding-left: 10px;
	position: relative;
	flex-grow: 1;

	&:before{
		content: '';
		display: block;
		width: 4px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: var(--alternative-primary-color);
		border-radius: 4px;
	}
}

.Arrow{
	svg{
		width: 24px;
		height: 24px;
		transition: transform 0.3s;

		:global(.fill){
			fill: var(--secondary-bg-icons-color);
			transition: fill 0.3s;
		}
	}

	&.active{
		svg{
			transform: rotate(180deg);

			:global(.fill){
				fill: var(--primary-bg-icons-color);
			}
		}
	}
}

.SearchIcon{
	svg{
		width: 24px;
		height: 24px;
		transition: transform 0.3s;

		:global(.fill){
			fill: var(--primary-text-color);
			transition: fill 0.3s;
		}
	}
}

.Content{
	transition: max-height 0.5s;
	max-height: 0;
	overflow-y: hidden;

	&.active{
		max-height: 100vh;
	}

	&.open{
		max-height: unset;
	}
}