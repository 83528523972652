.Guidelines{
	background: var(--primary-bg-color);
	min-height: calc(100% - 240px);
}

.Controls{
	height: 36px;
	border-bottom: 1px solid var(--alternative-secondary-bg-color);
}

.Diagnose{
	background: var(--primary-bg-color);
	border-bottom: 1px solid var(--alternative-secondary-bg-color);
	margin-bottom: 10px;

	.Header{
		cursor: pointer;
		padding: 17px 7px;
		background-color: var(--secondary-bg-color);
		border-bottom: 1px solid var(--alternative-secondary-bg-color);
		flex-wrap: wrap;
	}

	.Arrow{
		svg{
			width: 24px;
			height: 24px;
			transition: transform 0.3s;

			:global(.fill){
				fill: var(--primary-text-color);
				transition: fill 0.3s;
			}
		}

		&.active{
			svg{
				transform: rotate(180deg);

				:global(.fill){
					fill: var(--primary-bg-icons-color);
				}
			}
		}
	}

	.MoreIcon{
		height: 16px;

		:global(.fill){
			fill: var(--primary-text-color);
		}
	}

	.Content{
		transition: max-height 0.5s;
		max-height: 0;
		overflow-y: hidden;

		&.active{
			max-height: 100vh;
		}

		&.open{
			max-height: unset;
		}
	}

	&.NoGuidelines{
		.Header{
			border: none;
		}
	}
}

.List{
	padding: 17px 12px;
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	grid-row-gap: 14px;
	grid-template-rows: min-content;
}

.TreatmentText{
	&.done{
		text-decoration: line-through;
		color: var(--disable-button-text-color)
	}
}