.List{
	flex-wrap: wrap;
}

.Empty{
	width: 100%;
	margin: 10px 0 20px;
	color: var(--secondary-bg-icons-color);
}

.Item{
	margin: 0 10px 10px 0;
	padding: 4px 20px;
	border: 1px solid;
	border-radius: 10px;
	text-align: center;

	&[data-risk = "extreme"]{
		border-color: #c50000;
	}
	&[data-risk = "high"]{
		border-color: #FF3E1E;
	}
	&[data-risk = "medium"]{
		border-color: #FFA726;
	}
	&[data-risk = "low"]{
		border-color: #07B951;
	}
}