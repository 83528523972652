.Label{
	color: var(--sidebar-primary-text-color);
}

.Value{
	color: var(--primary-text-color);
}

.AdditionalInfo{
	color: var(--primary-text-color);
	font-size: var(--text-size-x3);
	line-height: var(--text-line-height-x3);
}

.AdditionalInfoFullscreen{
	position: fixed;
	background-color: var(--primary-bg-color);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 11;
	padding: 1rem;
	overflow-y: auto;
}