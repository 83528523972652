.ProgressBar{
    width: 250px;
    height: 10px;
    background-color: var(--alternative-secondary-bg-color);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.ProgressBarFill{
    height: 100%;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.5s;
}

.RiskChangeArrow{
    margin-left: 6px;

    &.RiskArrowUp{
        :global(.fill){
            fill: var(--high-risk);
        }
    }

    &.RiskArrowDown{
        :global(.fill){
            fill: var(--no-risk);
        }
    }
}

@media (max-width: 1400px) {
    .ProgressBar{
        width: 200px;
    }
}

@media (max-width: 1200px) {
    .ProgressBar{
        width: 500px;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .ProgressBar{
        width: 100%
    }
}