.Grid{
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-column-gap: 24px;
	grid-template-rows: repeat(2, minmax(0, 1fr));
	grid-row-gap: 20px;
	height: 100%;
}

.Wrapper {
	background: var(--primary-bg-color);
	border-radius: 10px;
	padding: 10px 0 20px;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&[data-size="doubled"]{
		grid-row: span 2;
	}

	&[data-hsize="doubled"]{
		grid-column: span 2;
	}
}

.Content{
	width: 100%;
	padding: 0 10px 0 20px;
}

.Header{
	padding: 0 20px 10px;
	border-bottom: 1px solid var(--alternative-secondary-bg-color);
}

.Search{
	width: 170px
}