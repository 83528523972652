.InfoItem{
	overflow: hidden;
	padding: 10px 0;

	.ItemLabel{
		flex-grow: 1;
	}

	.ItemValue{
		flex-shrink: 0;
		width: 60px
	}
}