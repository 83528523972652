.Side{
	padding: 8px 14px;
	min-width: 250px;
	flex-grow: 1;
}

.Title{
	padding: 24px 0 14px;
	border-bottom: 1px solid var(--alternative-secondary-bg-color);
}

.BasicInfo{
	padding: 12px 0 45px;
	border-bottom: 1px solid var(--alternative-secondary-bg-color);
}

.ChartBox{
	padding: 15px 0 55px;
	width: 100%;
	border-bottom: 1px solid var(--alternative-secondary-bg-color);

	&:last-child{
		border: none;
	}

	.Chart{
		max-width: 100%;
	}
}