.ReportLabel {
    cursor: pointer;
    border-radius: 10px;
    min-width: 125px;
    height: 28px;
    margin: 0 auto;


    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    display: flex !important;
    align-items: center;
    justify-content: center;

    &.btn{
        display: inline-block!important;
        min-width: unset;
        width: 120px;

        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}