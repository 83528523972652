.Tooltip {
    position: relative;

    &:before {
        content: attr(data-tooltip);
        display: none;
        background-color: var(--secondary-button-bg-color);
        color: var(--primary-text-color);
        border: 1px solid var(--alternative-secondary-bg-color);
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 2;
        top: 50%;
        right: calc(100% + 10px);
        transform: translateY(-50%);
        width: 350px;
        white-space: normal;
    }

    &:after {
        content: '';
        display: none;
        position: absolute;
        top: 50%;
        right: calc(100% + 6px);
        transform: translateY(-50%) rotate(45deg);
        width: 8px;
        height: 8px;
        background-color: var(--secondary-button-bg-color);
        border-right: 1px solid var(--alternative-secondary-bg-color);
        border-top: 1px solid var(--alternative-secondary-bg-color);
        z-index: 10;
    }

    &:hover {
        &:before {
            display: block;
        }

        &:after {
            display: block;
        }
    }
}