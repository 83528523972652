.Item {
    padding: 15px 0;
    border-bottom:  1px solid var(--alternative-secondary-bg-color);

    &:last-child{
        border-bottom: none;
    }
}

.Info{
    padding-left: 20px;

    .Data{
        margin-bottom: 15px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    .Value{
        padding-left: 10px;
    }
}