.Input{
	margin-bottom: 40px;

	&.Inactive{
		opacity: 0.5;
	}

	&:last-child{
		margin-bottom: 0;
	}
}

.InputTitle{
	margin-bottom: 12px;
}

.InputRow{
	margin-bottom: 12px;

	.Delete{
		cursor: pointer;

		svg{
			width: 24px;
			height: 24px;
		}

		:global(.fill){
			fill: var(--disable-icons-color);
		}

		&:hover :global(.fill){
			fill: var(--primary-text-color);
		}
	}

	.DeleteFiller{
		width: 24px;
	}

	&:last-child{
		margin-bottom: 0;
	}
}

.Field{

	&._hasContent{
		border-color: var(--sidebar-text-color) !important;
		background-color: var(--secondary-bg-color) !important;

		@media (min-width: 768px) {
			&:not(:focus):not(._focus):not(:hover) {
				& ~ div { // Hide buttons
					display: none;
				}

				& > div:last-child { // Hide arrow in select
					opacity: 0;
					pointer-events: none;
				}
			}
		}

		.Chips > div{
			border: 1px solid var(--sidebar-text-color) !important;
			background: var(--secondary-bg-color) !important;
			color: var(--primary-text-color);
		}
	}

	&:hover,
	&:focus,
	&._focus{
		border-color: var(--alternative-primary-color) !important;
		background-color: var(--primary-bg-color) !important;

		.Chips > div {
			border: 1px solid var(--alternative-primary-color) !important;
			background: var(--primary-bg-color) !important;
			color: var(--secondary-button-text-color);
		}
	}
}

.ConfirmField{
	margin-bottom: 20px;

	.FieldTitle{
		margin-bottom: 8px;
	}
}